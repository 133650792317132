import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, TextInput, Alert, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const App = ({ navigation }) => {
  const [searchText, setSearchText] = useState('');
  const [userData, setUserData] = useState({});
  const [recentCollabs, setRecentCollabs] = useState([]);

  useEffect(() => {
    fetchUserData(); // Fetch user data on component mount
    fetchRecentCollabs(); // Fetch recent collabs on component mount
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbay.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data); // Update user data state
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const fetchRecentCollabs = () => {
    fetch('https://collabbay.com/APIControllers/RetrieveRecentCollabs.php')
      .then(response => response.json())
      .then(data => {
        setRecentCollabs(data); // Update recent collabs state
      })
      .catch(error => {
        console.error('Error fetching recent collabs: ', error);
      });
  };

  const handleSearch = () => {
    if (searchText.trim() !== '') {
      navigation.navigate('CreateSubmissionPage', { ListingID: searchText });
    }
  };

  const handlePersonIconPress = () => {
    if (userData.Username) {
      navigation.navigate('UserRecapPage');
    } else {
      navigation.navigate('LogInPage');
    }
  };

  const handleOfferPress = () => {
    navigation.navigate('BrandListings');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/EndSession.php');
      const data = await response.json();
      if (data.success) {
        Alert.alert('Logout', 'Logout successful');
        navigation.navigate('Homepage');
      } else {
        Alert.alert('Logout', 'No active session found');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleScanIconPress = () => {
    Linking.openURL('https://collabbay.com/QR.html');
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Banner Section */}
        <View style={styles.bannerContainer}>
          <Image
            style={styles.bannerImage}
            source={{ uri: 'https://collabbay.com/CollabbayLogoDark.png' }}
            resizeMode="contain"
          />
        </View>

        {/* Popular Brands Section */}
        <View style={styles.section}>
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>Time to collab.</Text>
            {!userData.Username && (
              <TouchableOpacity
                style={styles.signUpButton}
                onPress={() => navigation.navigate('LogInPage')}>
                <Text style={styles.signUpButtonText}>Sign up as a content creator</Text>
              </TouchableOpacity>
            )}
          </View>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>Create.</Text>
              <TouchableOpacity onPress={() => navigation.navigate('CreateSubmissionPage')}>
                <Text style={styles.cardSubTitle}>Create content.</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>Post.</Text>
              <Text style={styles.cardSubTitle}>Share content.</Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>Exchange.</Text>
              <Text style={styles.cardSubTitle}>Redeem views.</Text>
            </View>
          </ScrollView>
        </View>

        <View style={styles.offerContainer}>
        <TouchableOpacity style={styles.offerButton} onPress={handleOfferPress}>
  <Ionicons name="pricetag" size={24} color="black" style={styles.offerIcon} />
  <Text style={styles.offerText}> Explore Brand Offers</Text>
</TouchableOpacity>

<TouchableOpacity onPress={() => navigation.navigate('LogInPage')}>
  <Text style={styles.offerLogText}>
    {!userData.Username && 'Log in to view'}
  </Text>
</TouchableOpacity>


</View>

<View style={styles.scanOptionalContainer}>
<Text>or scan the QR in-store</Text>
</View>

        {/* Large QR Scan Icon */}
        <View style={styles.bottomBackground}>
          <TouchableOpacity onPress={handleScanIconPress}>
            <Ionicons name="scan" size={200} color="black" style={styles.qrIcon} />
          </TouchableOpacity>

          {/* Search Bar */}
          <View style={styles.searchBar}>
            <Ionicons name="search" size={24} color="white" style={styles.searchIcon} />
            <TextInput
              style={styles.searchInput}
              placeholder="Find a collabbay by ID"
              placeholderTextColor="white"
              onChangeText={(text) => setSearchText(text)}
              onSubmitEditing={handleSearch} 
            />
          </View>

          {userData.Username && recentCollabs.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>My Recent collabs</Text>
              {recentCollabs.map((collab, index) => (
                <TouchableOpacity
                  key={index}
                  style={styles.collabCard}
                  onPress={() => navigation.navigate('CollabCampaignDetailsPage', { CollabID: collab.CollabID })}
                >
                  <Text style={styles.collabCardTitle}>{collab.BrandName}</Text>
                  <Text style={styles.collabCardSubTitle}>{collab.DateTime}</Text>
                  <Text style={styles.collabCardSubTitle}>{collab.AdHeader}</Text>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
      </ScrollView>

  <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}> 
<Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy. </Text> 
</TouchableOpacity> 


      {/* Footer Section */}
      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserCollabDashboard')}>
          <Ionicons name="qr-code" size={24} color="black" />          
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />          
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={handleScanIconPress}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        {userData.Username ? ( // Conditionally render based on userData presence
          <TouchableOpacity style={styles.footerIcon} onPress={handlePersonIconPress}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 10,
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  searchBar: {
    backgroundColor: 'black',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginTop: 40,
    marginBottom: 10,
  },
  searchInput: {
    flex: 1,
    color: 'white',
    fontSize: 16,
    marginLeft: 10,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  card: {
    width: 150,
    height: 200,
    backgroundColor: 'black',
    marginRight: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
  },
  cardSubTitle: {
    color: 'white',
    marginTop: 5,
    textAlign: 'center',
  },
  collabCard: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
  },
  collabCardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  collabCardSubTitle: {
    fontSize: 14,
    color: 'gray',
  },
  qrIcon: {
    alignSelf: 'center',
    marginTop: 20,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 10,
    paddingBottom: 60, // Adjusted for the footer height
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  signUpButton: {
    backgroundColor: '#FDF05D',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  signUpButtonText: {
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
  },
  offerButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
    marginTop: 10,
    marginBottom: 10,
    width: '75%', // Set width to 75% of screen width
    justifyContent: 'center',ion: 3,
  },
  offerIcon: {
    marginRight: 10,
  },
  offerText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
  offerLogText: {
    color: 'black',
    fontSize: 12,
    fontWeight: 'bold',
  },
  offerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  scanOptionalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  linkText: { 
    fontSize: 9, 
    color: 'black', 
    textDecorationLine: 'underline',
    textAlign: 'center',

  }, 
});

export default App;
 