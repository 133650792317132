import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Alert, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';

const ImageUpload = () => {
    const [postURL, setPostURL] = useState('');
    const [postPlatform, setPostPlatform] = useState('');
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(null);

    const pickImage = async () => {
        const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permissionResult.granted === false) {
            Alert.alert("Permission to access camera roll is required!");
            return;
        }

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.canceled) {
            setImage(result.uri);
        }
    };

    const handleSubmit = async () => {
        if (!image) {
            Alert.alert("Please select an image");
            return;
        }

        let localUri = image;
        let filename = localUri.split('/').pop();

        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        let formData = new FormData();
        formData.append('image', { uri: localUri, name: filename, type });
        formData.append('posturl', postURL);
        formData.append('postplatform', postPlatform);
        formData.append('message', message);

        const response = await fetch('https://collabbay.com/APIControllers/ImageUploadForm.php', {
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.ok) {
            Alert.alert("The file has been uploaded and the information has been inserted into the database.");
        } else {
            Alert.alert("Sorry, there was an error uploading your file.");
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.label}>Select Image to Upload:</Text>
            <Button title="Pick an image from camera roll" onPress={pickImage} />

            {image && <Text style={styles.imageText}>{image}</Text>}

            <Text style={styles.label}>Post URL:</Text>
            <TextInput
                style={styles.input}
                value={postURL}
                onChangeText={setPostURL}
                placeholder="Post URL"
            />

            <Text style={styles.label}>Post Platform:</Text>
            <Picker
                selectedValue={postPlatform}
                style={styles.picker}
                onValueChange={(itemValue) => setPostPlatform(itemValue)}
            >
                <Picker.Item label="Select Platform" value="" />
                <Picker.Item label="Instagram" value="Instagram" />
                <Picker.Item label="Facebook" value="Facebook" />
                <Picker.Item label="Snapchat" value="Snapchat" />
                <Picker.Item label="TikTok" value="TikTok" />
            </Picker>

            <Text style={styles.label}>Message:</Text>
            <TextInput
                style={styles.input}
                value={message}
                onChangeText={setMessage}
                placeholder="Message"
            />

            <Button title="Upload Image" onPress={handleSubmit} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f9f9f9',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    input: {
        width: '100%',
        padding: 10,
        marginBottom: 15,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
    },
    picker: {
        width: '100%',
        marginBottom: 15,
    },
    imageText: {
        marginBottom: 15,
    },
});

export default ImageUpload;