import React, { useState, useEffect, useRef } from 'react';
import { 
  SafeAreaView, 
  StyleSheet, 
  View, 
  Text, 
  ScrollView, 
  Image, 
  TextInput, 
  TouchableOpacity, 
  Clipboard, 
  Alert 
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const App = ({ navigation }) => {
  const [cardsData, setCardsData] = useState([]);
  const [currentLetter, setCurrentLetter] = useState(null);
  const [letters, setLetters] = useState([]);
  const scrollViewRef = useRef();

  useEffect(() => {
    fetch('https://collabbay.com/APIControllers/RetrieveCollabs.php')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setCardsData(data);
          const lettersArray = data.map(card => card.BrandName[0].toUpperCase());
          const uniqueLetters = Array.from(new Set(lettersArray));
          setLetters(uniqueLetters.sort());
          scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
        } else {
          console.error('Data received is not an array:', data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const scrollToLetter = (letter) => {
    const index = cardsData.findIndex(card => card.BrandName.toUpperCase().startsWith(letter));
    if (index !== -1) {
      scrollViewRef.current.scrollTo({ x: 0, y: index * 240, animated: true });
    }
  };

  const handleScroll = (event) => {
    const offsetY = event.nativeEvent.contentOffset.y;
    const index = Math.floor(offsetY / 240);
    if (index >= 0 && index < cardsData.length) {
      const firstLetter = cardsData[index].BrandName[0].toUpperCase();
      setCurrentLetter(firstLetter);
    } else {
      setCurrentLetter(null);
    }
  };

  const [userData, setUserData] = useState({});

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbay.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const copyToClipboard = (text) => {
    Clipboard.setString(text);
    Alert.alert('Copied to clipboard', 'Share to generate views');
  };

  return (
    
    <SafeAreaView style={styles.container}>

<View style={styles.letterScroll}>
        {letters.map((letter, index) => (
          <TouchableOpacity 
            key={index}
            style={styles.letterButton}
            onPress={() => scrollToLetter(letter)}
          >
            
            <Text style={[styles.letterText, currentLetter === letter ? styles.currentLetter : null]}>{letter}</Text>
          </TouchableOpacity>
        ))}
      </View>

      <ScrollView 
        contentContainerStyle={styles.contentContainer}
        ref={scrollViewRef}
        onScroll={handleScroll}
        scrollEventThrottle={16}
      >
        <Text style={styles.sectionTitle}>My Collabs</Text>
        <View style={styles.section}>
          {cardsData.map((card, index) => (
            <TouchableOpacity 
              key={index}
              style={styles.card} 
              onPress={() => navigation.navigate('CollabCampaignDetailsPage', { CollabID: card.CollabID })}
            >
              <Ionicons name="qr-code" size={24} color="black" style={styles.qrIcon} />
              <Image 
                style={styles.logo}
                source={{ uri: 'https://collabbay.com/CollabbayLogoDark.png' }}
              />
              <Text style={styles.cardTitle}>{card.BrandName}</Text>
              <Text style={styles.cardSubText}>{card.Redeem_Message}</Text>
              <Text style={styles.cardSubText}>{card.CollabID}</Text>
              <Text style={styles.cardSubText}>{card.Created_At}</Text>
              <TouchableOpacity style={styles.promoteLinkSection} onPress={() => copyToClipboard(`collabbay.com/collab/${card.CollabID}`)}>
                <Ionicons name="copy" size={20} color="black" style={styles.copyIcon} />
                <Text style={styles.promoteLinkText}>Promote Link</Text>
              </TouchableOpacity>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>

      <View style={styles.footer}>
  <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
    <Ionicons name="home" size={24} color="black" />
  </TouchableOpacity>

  <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserCollabDashboard')}>
    <Ionicons name="qr-code" size={24} color="black" />
  </TouchableOpacity>

  <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
    <Ionicons name="calendar" size={24} color="black" />
  </TouchableOpacity>

  <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('QRCodeScannerPage')}>
    <Ionicons name="scan" size={24} color="black" />
  </TouchableOpacity>

  {userData.Username ? (
    <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserRecapPage')}>
      <Ionicons name="person" size={24} color="black" />
    </TouchableOpacity>
  ) : (
    <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
      <Ionicons name="person" size={24} color="black" />
    </TouchableOpacity>
  )}
</View>

    </SafeAreaView>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    paddingRight: 40, // Adjust to accommodate the letter navigation
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'black',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 20,
    marginBottom: 10,
  },
  section: {
    marginBottom: 20,
  },
  card: {
    width: '100%',
    height: 240,
    backgroundColor: '#000',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative',
    marginBottom: 10,
  },
  logo: {
    width: 80,
    height: 80,
    marginBottom: 10,
    borderRadius: 40,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#FFF',
  },
  cardSubText: {
    fontSize: 12,
    color: '#FFF',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  promoteLinkSection: {
    marginTop: 10,
    flexDirection: 'row',
    backgroundColor: '#FDF05D',
    padding: 5,
    borderRadius: 5,
  },
  promoteLinkText: {
    color: '#000',
    fontWeight: 'bold',
    marginLeft: 5,
  },
  copyIcon: {
    marginRight: 5,
  },
  letterScroll: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  letterButton: {
    padding: 5,
  },
  letterText: {
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: '#FDF05D',
    color: '#000',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 20,
    marginBottom: 10,
  },
  currentLetter: {
    backgroundColor: '#FDF05D',
  },
  qrIcon: {
    marginBottom: 10,
  },
});

export default App;
