import React, { useState, useEffect } from 'react';
import { 
  SafeAreaView, 
  StyleSheet, 
  View, 
  Text, 
  ScrollView, 
  Image, 
  Linking,
  TouchableOpacity,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';

const UserRecapPage = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const isFocused = useIsFocused();

  useEffect(() => {
    fetchUserData();
  }, [isFocused]);

  const fetchUserData = () => {
    fetch('https://collabbay.com/APIControllers/GetSessionData.php')
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://collabbay.com/APIControllers/EndSession.php');
      const data = await response.json();
  
      // Clear user data upon logout
      setUserData({});
  
      // Reload the app after logout
      window.location.reload(); // This will refresh the app
  
    } catch (error) {
      console.error('Error:', error);
      // Handle error if fetch or reload fails
      // Optionally, show an alert or log the error
    }
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handlePersonIconPress = () => {
    if (userData.Username) {
      navigation.navigate('UserRecapPage');
    } else {
      navigation.navigate('LogInPage');
    }
  };

  const handleEditProfile = () => {
    // Navigate to external URL for editing profile
    window.open('https://collabbay.com/UpdateProfile.html');
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    }
    return age;
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Trendy Slide Section */}
        <View style={styles.bannerContainer}>
          <Image
            style={styles.bannerImage}
            source={{ uri: 'https://collabbay.com/CollabbayLogoDark.png' }}
            resizeMode="contain"
          />
        </View>

        {/* Profile Section */}
        <View style={styles.profileSection}>
          <Image
            style={styles.profilePicture}
            source={{ uri: userData.ProfileImage }}
            resizeMode="cover"
          />
          <View style={styles.profileDetails}>
            <View style={styles.usernameContainer}>
              <Text style={styles.usernameText}>@{userData.Username}</Text>
            </View>
            {userData.Status === 'Public Figure' && (
              <View style={styles.statusBadge}>
                <Ionicons name="checkmark-circle" size={16} color="black" />
                <Text style={styles.statusText}>Public Figure</Text>
              </View>
            )}
            <Text style={styles.name}>{userData.Name}</Text>
            <Text style={styles.bio}>{userData.Interests}</Text>
            {userData.DOB ? (
              <Text style={styles.age}>Age: {calculateAge(userData.DOB)}</Text>
            ) : (
              <Text style={styles.age}></Text> // Displays a blank field if DOB is null
            )}
            <Text style={styles.location}>{userData.Location}</Text>
          </View>
        </View>

        {/* New Data Section */}
        <View style={styles.dataCard}>
          <View style={styles.dataRow}>
            <Ionicons name="person" size={20} color="black" style={styles.dataIcon} />
            <Text style={styles.dataText}>Username: {userData.PlatformUsername}</Text>
          </View>
          <View style={styles.dataRow}>
            <Ionicons name="people" size={20} color="black" style={styles.dataIcon} />
            <Text style={styles.dataText}>Followers: {userData.PlatformFollowers}</Text>
          </View>
          <View style={styles.dataRow}>
            <Ionicons name="list" size={20} color="black" style={styles.dataIcon} />
            <Text style={styles.dataText}>Categories: {userData.ContentCategories}</Text>
          </View>
          <View style={styles.dataRow}>
            <Ionicons name="checkmark-circle" size={20} color="black" style={styles.dataIcon} />
            <Text style={styles.dataText}>Verification Status: {userData.PlatformVerifStatus}</Text>
          </View>
        </View>

        {/* Edit Profile Button */}
        <TouchableOpacity style={styles.editProfileButton} onPress={handleEditProfile}>
          <Ionicons name="create" size={20} color="black" style={{ marginRight: 10 }} />
          <Text style={styles.editProfileText}>Edit Profile</Text>
        </TouchableOpacity>

        {/* Logout Button */}
        <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
          <Text style={styles.logoutText}>Log Out</Text>
        </TouchableOpacity>

      </ScrollView>

      <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}> 
        <Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy. </Text> 
      </TouchableOpacity> 

      {/* Footer */}
      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserCollabDashboard')}>
          <Ionicons name="qr-code" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />          
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('QRCodeScannerPage')}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>
        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={handlePersonIconPress}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 10,
  },
  profileSection: {
    alignItems: 'center',
    marginTop: 20,
  },
  profilePicture: {
    width: 150,
    height: 150,
    borderRadius: 75,
    borderWidth: 5,
    borderColor: '#fff',
  },
  profileDetails: {
    alignItems: 'center',
    marginTop: 10,
  },
  usernameContainer: {
    backgroundColor: 'black',
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  usernameText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  statusBadge: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#d3d3d3',
    borderRadius: 12,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginTop: 5,
  },
  statusText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    marginLeft: 5,
  },
  name: {
    fontSize: 18,
    paddingTop: 2,
    color: '#666',
  },
  bio: {
    paddingTop: 2,
    marginBottom: 5,
    color: '#666',
  },
  age: {
    fontSize: 18,
    color: '#666',
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  location: {
    fontSize: 18,
    color: '#666',
    paddingBottom: 10,
  },
  textSection: {
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  textBackground: {
    backgroundColor: 'black',
    padding: 20,
    borderRadius: 10,
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 22,
  },
  editProfileButton: {
    backgroundColor: '#FDF05D',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 40,
    borderRadius: 15,
    marginTop: 20,
  },
  editProfileText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  logoutButton: {
    backgroundColor: 'black',
    paddingVertical: 15,
    paddingHorizontal: 50,
    borderRadius: 15,
    marginTop: 10,
  },
  logoutText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bannerContainer: {
    marginBottom: 15,
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'black',
    width: '100%',
    height: 100,
  },
  bannerImage: {
    width: '25%',
    height: 100,
    borderRadius: 10,
    zIndex: 1,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  linkText: { 
    fontSize: 9, 
    color: 'black', 
    textDecorationLine: 'underline', 
    textAlign: 'center',
  },
  dataCard: {
    backgroundColor: '#f8f8f8',
    borderRadius: 10,
    padding: 15,
    marginTop: 20,
    elevation: 2,
  },
  dataRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  dataIcon: {
    marginRight: 10,
  },
  dataText: {
    fontSize: 16,
    color: '#333',
  },
});

export default UserRecapPage;
