import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Clipboard, TextInput, Button, Alert, Picker, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import QRCode from 'react-native-qrcode-svg';

const CollabCampaignDetails = ({ route, navigation }) => {
  const { CollabID } = route.params;
  const [secretQRID, setSecretQRID] = useState('');
  const [ipCount, setIpCount] = useState(null);
  const [tierData, setTierData] = useState(null);
  const [redeemableTier, setRedeemableTier] = useState('None');
  const [redeemableValue, setRedeemableValue] = useState(0);
  const [redeemableOffer, setRedeemableOffer] = useState(0);
  const [userData, setUserData] = useState({});
  const [brandEmail, setBrandEmail] = useState(null); 
  const [brandName, setBrandName] = useState(null);
  const [message, setMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [latestUpdate, setLatestUpdate] = useState({ Message: '', CreditAvailableStart: '', Status: '' });
  const [mediaContent, setMediaContent] = useState([]);
  const [apiMessages, setApiMessages] = useState([]);
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [redeemDateTime, setRedeemDateTime] = useState(null);



  const [isExpanded, setIsExpanded] = useState(false);

const handleToggleExpand = () => {
  setIsExpanded(!isExpanded);
};

  useEffect(() => {
    fetchSecretQRID();
    fetchNewApi();
    fetchUserData();
    fetchBrandDetails();
    fetchLatestCollabUpdate();
    fetchMediaContent();
    fetchUserMediaValidation();
    fetchCreditAvailabilityValidation();
    fetchBrandApprovalValidation();
    checkRedeemStatus();
  }, []);


  const fetchBrandDetails = () => {
    fetch(`https://collabbay.com/APIControllers/GetCollabBrandName.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].BrandName) {
          const fetchedBrandName = data[0].BrandName;
          setBrandName(fetchedBrandName);
          fetchBrandEmail(fetchedBrandName);
        } else {
          console.error('Brand name not found');
        }
      })
      .catch(error => console.error('Error fetching brand name:', error));
  };

  const fetchBrandEmail = (brandName) => {
    fetch(`https://collabbay.com/APIControllers/GetCollabBrandEmail.php?BrandName=${brandName}`)
      .then(response => response.json())
      .then(data => {
        if (data && data[0] && data[0].UserEmail) {
          setBrandEmail(data[0].UserEmail);
        } else {
          console.error('Brand email not found');
        }
      })
      .catch(error => console.error('Error fetching brand email:', error));
  };

  const fetchSecretQRID = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveQRSecretForScan.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data.SecretQRID) {
          setSecretQRID(data.SecretQRID);
        } else {
          console.error('SecretQRID not found');
        }
      })
      .catch(error => console.error('Error fetching SecretQRID:', error));
  };

  const fetchNewApi = () => {
    fetch(`https://collabBay.com/APIControllers/RetrieveCollabViewCount.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setIpCount(parseInt(responseData.ipCount));
        fetchTierAssignApi();
      })
      .catch(error => {
        console.error('Error fetching IP count:', error);
      });
  };

  const fetchTierAssignApi = () => {
    fetch(`https://collabbay.com/APIControllers/RetrieveCollabTierData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(responseData => {
        setTierData(responseData);
      })
      .catch(error => {
        console.error('Error fetching tier data:', error);
      });  
  };

  const fetchUserData = () => {
    fetch(`https://collabay.com/APIControllers/GetSessionData.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        setUserData(data);
      })
      .catch(error => {
        console.error('Error fetching user data: ', error);
      });
  };

  const fetchLatestCollabUpdate = () => {
    fetch(`https://collabbay.com/APIControllers/GetLatestCollabUpdate.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setLatestUpdate({
            Message: data.Message || '',
            CreditAvailableStart: data.CreditAvailableStart || '',
            Status: data.Status || '',
            Credits: data.Credits || '',
            BrandName: data.BrandName || '',
          });
        } else {
          console.error('Latest collab update not found');
        }
      })
      .catch(error => {
        console.error('Error fetching latest collab update:', error);
      });
  };



  const fetchMediaContent = () => {
    fetch(`https://collabbay.com/APIControllers/GetCollabMedia.php?CollabID=${CollabID}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setMediaContent(data);
        } else {
          console.log('No media content found');
        }
      })
      .catch(error => {
        console.error('Error fetching media content:', error);
      });
  };



  const fetchUserMediaValidation = async () => {
    try {
        const response = await fetch(`https://collabbay.com/APIControllers/ErrorCheckUserMedia.php?CollabID=${CollabID}`);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, No post uploaded - credits Unredeemable" 
            : "Content uploaded";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching user media validation:', error);
    }
};

const fetchCreditAvailabilityValidation = async () => {
  try {
      const response = await fetch(`https://collabbay.com/APIControllers/ErrorCheckCreditAvailability.php?CollabID=${CollabID}`);
      
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      
      const result = await response.json(); // Parse as JSON

      const message = result.message === 'Not Validated' 
          ? "Warning, credits expired or not yet available, check the start date" 
          : "Within credit time availability";
      
      setApiMessages(prev => [...prev, message]);
  } catch (error) {
      console.error('Error fetching credit availability validation:', error);
  }
};



  const fetchBrandApprovalValidation = async () => {
    try {
        const response = await fetch(`https://collabbay.com/APIControllers/ErrorCheckBrandApproval.php?CollabID=${CollabID}`);
        const result = await response.json(); // Parse as JSON

        const message = result.message === 'Not Validated' 
            ? "Warning, collab not approved by brand - credits unredeemable"
            : "Collab approved by brand";
        
        setApiMessages(prev => [...prev, message]);
    } catch (error) {
        console.error('Error fetching brand approval validation:', error);
    }
};





const checkRedeemStatus = () => {
  fetch(`https://collabbay.com/APIControllers/CheckCollabRedeemedStatus.php?CollabID=${CollabID}`)
    .then(response => response.json())
    .then(data => {
      if (data.message === "Redeem Found") {
        setIsRedeemed(true);
        setRedeemDateTime(data.dateTime); // Store the DateTime
      }
    })
    .catch(error => console.error('Error fetching redeem status:', error));
};

const renderRedeemStatus = () => {
  if (isRedeemed) {
    return (
      <View style={styles.redeemedContainer}>
        <Text style={styles.redeemedText}>Redeemed on {redeemDateTime}</Text> {/* Display the DateTime */}
      </View>
    );
  }
  return null;
};




  useEffect(() => {
    calculateRedeemableValues();
  }, [ipCount, tierData]);

  const calculateRedeemableValues = () => {
    if (ipCount !== null && tierData !== null) {
      if (ipCount >= 0 && ipCount < tierData.TierOneTarget) {
        setRedeemableTier('None');
        setRedeemableValue(0);
        setRedeemableOffer(0);
      } else if (ipCount >= tierData.TierOneTarget && ipCount < tierData.TierTwoTarget) {
        setRedeemableTier('1');
        setRedeemableValue(tierData.TierOneTarget);
        setRedeemableOffer(tierData.TierOneOffer);
      } else if (ipCount >= tierData.TierTwoTarget && ipCount < tierData.TierThreeTarget) {
        setRedeemableTier('2');
        setRedeemableValue(tierData.TierTwoTarget);
        setRedeemableOffer(tierData.TierTwoOffer);
      } else if (ipCount >= tierData.TierThreeTarget) {  
        setRedeemableTier('3');
        setRedeemableValue(tierData.TierThreeTarget);
        setRedeemableOffer(tierData.TierThreeOffer);
      }
    }
  };

  const handleCopyURL = () => {
    Clipboard.setString(`collabbay.com/collab/${CollabID}`);
    alert('URL copied to clipboard!');
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const navigateToMediaUpload = () => {
    Linking.openURL(`https://collabbay.com/UploadPost.html?CollabID=${CollabID}`);
  };

  const renderDatePicker = () => {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < 30; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      dates.push(date.toDateString());
    }

    return (
      <View style={styles.datePickerContainer}>
        <Text style={styles.datePickerLabel}>Move Date:</Text>
        <ScrollView horizontal>
          {dates.map((date, index) => (
            <TouchableOpacity
              key={index}
              style={[styles.dateOption, { backgroundColor: selectedDate === date ? '#FDF05D' : 'white' }]}
              onPress={() => setSelectedDate(date)}
            >
              <Text style={styles.dateOptionText}>{date}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </View>
    );
  };

  const renderTimePicker = () => {
    const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

    return (
      <View style={styles.timePickerContainer}>
        <Text style={styles.timePickerLabel}>Select Time:</Text>
        <View style={styles.pickerContainer}>
          <Text style={styles.pickerLabel}>Hour:</Text>
          <Picker
            selectedValue={selectedHour}
            style={styles.picker}
            onValueChange={(itemValue) => setSelectedHour(itemValue)}
          >
            {hours.map(hour => (
              <Picker.Item key={hour} label={hour} value={hour} />
            ))}
          </Picker>
        </View>
        <View style={styles.pickerContainer}>
          <Text style={styles.pickerLabel}>Minute:</Text>
          <Picker
            selectedValue={selectedMinute}
            style={styles.picker}
            onValueChange={(itemValue) => setSelectedMinute(itemValue)}
          >
            {minutes.map(minute => (
              <Picker.Item key={minute} label={minute} value={minute} />
            ))}
          </Picker>
        </View>
      </View>
    );
  };


  const handleSubmit = () => {
    const fullDateTime = `${selectedDate} ${selectedHour}:${selectedMinute}`;
  
    fetch(`https://collabbay.com/APIControllers/UserUpdateCollab.php?CollabID=${CollabID}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Message: message,
        CreditAvailableStart: fullDateTime,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Update submitted');
          // Reload the app or navigate to the homepage
          window.location.reload(); // Refresh the app to reflect changes
        } else {
          alert('There was an error updating your collab details.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('An unexpected error occurred.');
      });
  };

  const renderMediaContent = () => {
    return (
      <View style={styles.mediaContentSection}>
        <Text style={styles.mediaContentHeader}>Content</Text>
        {mediaContent.map((item, index) => (
          <View key={index} style={styles.mediaItemContainer}>
            <Image
              style={styles.mediaImage}
              source={{ uri: item.Image }}
              resizeMode="contain"
            />
            <View style={styles.mediaContentTextSection}>
              <Text style={styles.mediaPlatformText}>
                {item.PostPlatform}
              </Text>
              <TouchableOpacity
  onPress={() => {
    const formattedURL = item.PostURL.startsWith('http')
      ? item.PostURL
      : `https://${item.PostURL}`;
    Linking.openURL(formattedURL);
  }}
>
  <Text style={styles.mediaURLText}>
    <Ionicons name="link" size={20} color="blue" />
    {item.PostURL}
  </Text>
</TouchableOpacity>
              <Text style={styles.mediaMessageText}>
                <Ionicons name="chatbubble-ellipses" size={20} color="black" /> {item.Message}
              </Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>

<TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>

      <ScrollView contentContainerStyle={styles.contentContainer}>

        <Text style={styles.header}>{latestUpdate.BrandName} Collab Campaign Details</Text>
 
        <Text style={styles.creditsHeader}>£{latestUpdate.Credits}</Text>
        <View style={styles.qrCodeContainer}>
          <QRCode
            value={`https://collabbay.com?type=PromoterScan&QRID=${secretQRID}`}
            size={200}
            color="black"
            backgroundColor="white"
          />
        </View>


 {renderRedeemStatus()}

        <Text style={styles.scanCTALabel}>Present QR to Redeem Collab Credits</Text>
        <Text style={styles.emailCTA}>Contact Brand : {brandEmail ? brandEmail : ' '}</Text>

        <View style={styles.statusCard}>
          <Text style={styles.subheader}>Last Status</Text>
          <Text style={styles.statusLabel}>Message: {latestUpdate.Message}</Text>
          <Text style={styles.statusLabel}>Credit Available Start: {latestUpdate.CreditAvailableStart}</Text>
          <Text style={styles.statusLabel}>Status: {latestUpdate.Status}</Text>
        </View>

        <TouchableOpacity style={styles.copyCollabButton} onPress={handleCopyURL}>
          <Text style={styles.copyButtonText}>Copy Collab URL</Text>
        </TouchableOpacity>


        <View style={styles.apiMessagesContainer}>
      {apiMessages.map((msg, index) => (
        <View
          key={index}
          style={[
            styles.apiMessage,
            { backgroundColor: msg.includes('Warning') ? '#EC4646' : '#74dc8a' }
          ]}
        >
          <Ionicons
            name={msg.includes('Warning') ? 'alert-circle' : 'checkmark-circle'}
            size={24}
            color="white"
            style={styles.icon}
          />
          <Text style={styles.apiMessageText}>{msg}</Text>
        </View>
      ))}
    </View>


        <TouchableOpacity style={styles.header} onPress={handleToggleExpand}>
  <Text style={styles.updateSubheader}>Update your collab?
  <Ionicons name={isExpanded ? 'chevron-up' : 'chevron-down'} size={24} color="black" />
  </Text>

</TouchableOpacity>
{isExpanded && (
  <View style={styles.updateCollabCard}>
    <Text style={styles.scanCTALabel}>Any updates require brand approval before credits become available</Text>
    {renderDatePicker()}
    {renderTimePicker()}
    <TextInput
      style={styles.textInput}
      placeholder="Add a message?"
      value={message}
      onChangeText={setMessage}
    />
    <TouchableOpacity style={styles.button} onPress={handleSubmit}>
      <Text style={styles.buttonText}>Propose Update</Text>
    </TouchableOpacity>
  </View>
)}


        {/* Button to navigate to MediaUpload */}
        <TouchableOpacity style={[styles.button, { backgroundColor: '#FDF05D' }]} onPress={navigateToMediaUpload}>
          <Text style={styles.postButtonText}>Upload created content</Text>
        </TouchableOpacity>


        {renderMediaContent()}


      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'white',
    width: '95%',
    alignSelf: 'center', // Centers horizontally
    justifyContent: 'center', // Centers vertically
  },
  contentContainer: {
    paddingBottom: 32,
    paddingTop: 55,
  },

  header: {
    fontSize: 24,
    marginBottom: 16,
    textAlign: 'center',
  },
  creditsHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    textAlign: 'center',
  },
  qrCodeContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  label: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
  },
  datePickerContainer: {
    marginBottom: 20,
  },
  datePickerLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
  },
  dateOption: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginRight: 8,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
  },
  dateOptionText: {
    fontSize: 16,
    color: 'black',
  },
  timePickerContainer: {
    marginBottom: 16,
  },
  timePickerLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
  },
  pickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  pickerLabel: {
    fontSize: 16,
    marginRight: 8,
    color: 'black',
  },
  picker: {
    flex: 1,
    color: 'black',
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
    fontSize: 16,
    color: 'black',
  },
  scanCTALabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
  },
  emailCTA: {
    fontSize: 10,
    color: 'black',
    textAlign: 'center',
  },
  statusCard: {
    backgroundColor: 'black',
    borderRadius: 8,
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
  },
  updateCollabCard: {
    backgroundColor: '#F1F1F1',
    borderRadius: 8,
    borderColor: 'black',
    padding: 16,
    marginVertical: 16,
    elevation: 2,
    shadowColor: 'black',
  },
  subheader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'white',
  },
  updateSubheader: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
    marginLeft: 7,
    padding: 2,
     },
  copyCollabButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'black',
    paddingVertical: 12,
    marginBottom: 16,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: 20,
    paddingVertical: 12,
    marginBottom: 16,

  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  copyButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  statusLabel: {
    fontSize: 18,
    marginBottom: 8,
    color: 'white',
  },
  postButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  mediaContentSection: {
    marginTop: 16,
    paddingHorizontal: 8,
  },
  mediaContentHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: 'black',
    textAlign: 'center',
  },
  mediaItemContainer: {
    marginBottom: 24,
    borderRadius: 20,
    backgroundColor: '#FDF05D',  
    padding: 16,
    elevation: 5,
  },
  mediaImage: {
    width: '100%',
    height: 300,
    borderRadius: 20,
    marginBottom: 16,
  },
  mediaContentTextSection: {
    padding: 16,
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'center',
  },
  mediaPlatformText: {
    fontSize: 18,
    marginBottom: 8,
    color: 'black',
    textAlign: 'center',
  },
  mediaURLText: {
    fontSize: 16,
    color: 'blue',
    textDecorationLine: 'underline',
    marginBottom: 8,
    textAlign: 'center',
  },
  mediaMessageText: {
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
  apiMessagesContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  apiMessage: {
    borderRadius: 15,
    padding: 15,
    marginVertical: 5,
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  apiMessageText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    flex: 1,
  },
  icon: {
    marginRight: 10,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
   redeemedContainer: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redeemedText: {
    fontSize: 16,
    color: '#000',
    textAlign: 'center',
  },

});

export default CollabCampaignDetails;
