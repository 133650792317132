import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, TextInput, TouchableOpacity, Alert, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Calendar } from 'react-native-calendars';

const CreateSubmissionPage = ({ route, navigation }) => {
  const [listingData, setListingData] = useState(null);
  const [collabData, setCollabData] = useState({
    ListingID: '',
    BrandName: '',
    BrandURL: '',
    BrandUserID: '',
    Redeem_Message: '',
    AdHeader: '',
    ListingDescription: '',
    Credits: '',
    CreditAvailableStart: '',
    Message: '',
  });
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('1:00 AM');
  const [message, setMessage] = useState('');
  const [bookedSlots, setBookedSlots] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleBackPress = () => {
    navigation.goBack();
  };

  useEffect(() => {
    const fetchListingData = async () => {
      try {
        const response = await fetch(`https://collabbay.com/APIControllers/RetrieveOfferDescription.php?ListingID=${route.params.ListingID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch listing data');
        }
        const data = await response.json();
        setListingData(data);
        setCollabData(prevData => ({
          ...prevData,
          ListingID: data?.ListingID,
          BrandName: data?.BrandName,
          BrandURL: data?.BrandURL,
          BrandUserID: data?.BrandUserID,
          Redeem_Message: data?.Redeem_Message,
          AdHeader: data?.AdHeader,
          ListingDescription: data?.ListingDescription,
          Credits: data?.Credits
        }));
      } catch (error) {
        console.error('Error fetching listing data:', error);
      }
    };

    fetchListingData();
  }, [route.params.ListingID]);

  useEffect(() => {
    const fetchBookedSlots = async () => {
      try {
        const today = new Date();
        const fourDaysFromNow = new Date();
        fourDaysFromNow.setDate(today.getDate() + 4);
        const formattedStartDate = today.toISOString().split('T')[0];
        const formattedEndDate = fourDaysFromNow.toISOString().split('T')[0];

        const response = await fetch(`https://collabbay.com/APIControllers/RetrieveBookedTimes.php?ListingID=${route.params.ListingID}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
        if (!response.ok) {
          throw new Error('Failed to fetch booked slots');
        }
        const data = await response.json();
        setBookedSlots(data.bookedTimes || []);
      } catch (error) {
        console.error('Error fetching booked slots:', error);
      }
    };

    fetchBookedSlots();
  }, [route.params.ListingID]);

  const handleCollabPress = async () => {
    try {
      const fullDateTime = `${selectedDate} ${selectedTime}`;

      const updatedCollabData = {
        ...collabData,
        CreditAvailableStart: fullDateTime,
        Message: message.trim()
      };
      setCollabData(updatedCollabData);

      const postData = {
        ...updatedCollabData,
        ListingID: route.params.ListingID
      };

      const response = await fetch('https://collabbay.com/APIControllers/InsertCollabSubmission.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      const result = await response.json();

      if ('success' in result) {
        console.log('Data inserted successfully');
      } else {
        console.error('Failed to insert collab data');
        Alert.alert('Error', 'Failed to insert collab data');
      }

    } catch (error) {
      console.error('Error inserting collab data:', error);
      Alert.alert('Error', 'Failed to insert collab data');
    } finally {
      // Redirect to https://collabbay.com/
      window.location.href = 'https://collabbay.com/';
    }
  };

  const renderBookedSlots = () => {
    const displayedSlots = expanded ? bookedSlots : bookedSlots.slice(0, 3);
    
    return (
      <View style={styles.bookedSlotsContainer}>
        <Text style={styles.bookedSlotsTitle}>Reserved Slots</Text>
        <Text style={styles.bookedSlotsBody}>Currently won't be able to book these over the next week</Text>
        {displayedSlots.map((slot, index) => {
          const [date, time] = slot.CreditAvailableStart.split(' ');
          return (
            <View key={index} style={styles.bookedSlot}>
              <Text style={styles.bookedSlotText}>{date} - {time}</Text>
            </View>
          );
        })}
        {bookedSlots.length > 3 && (
          <TouchableOpacity
            style={styles.expandButton}
            onPress={() => setExpanded(!expanded)}
          >
            <Ionicons name={expanded ? "chevron-up" : "chevron-down"} size={16} color="black" />
            <Text style={styles.expandButtonText}>
              {expanded ? 'Show Less' : 'Show All'}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={styles.backContainer}>
          <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
            <Ionicons name="arrow-back" size={28} color="black" />
          </TouchableOpacity>
        </View>

        <View style={styles.logoContainer}>
          {listingData?.LogoImage ? (
            <Image
              source={{ uri: listingData.LogoImage }}
              style={styles.logo}
              resizeMode="cover"
            />
          ) : null}
        </View>

        <View style={styles.section}>
          <Text style={styles.headerSectionTitle}>{listingData?.BrandName} Collab Offer</Text>
          <View style={styles.descriptionBox}>
            <Text style={styles.description}>{listingData?.ListingDescription}</Text>
          </View>
        </View>

        <View style={styles.locationContainer}>
          <Ionicons name="location-sharp" size={20} color="black" />
          <Text style={styles.sectionTitleAddress}>{listingData?.FullAddress}</Text>
        </View>

        <View style={styles.creditsCard}>
          <Ionicons name="wallet" size={24} color="black" />
          <Text style={styles.creditsCardTitle}>Credits</Text>
          <Text style={styles.creditsCardSubTitle}>£{listingData?.Credits}</Text>
        </View>

        <View style={styles.calendarContainer}>
          <Calendar
            onDayPress={(day) => setSelectedDate(day.dateString)}
            markedDates={{
              [selectedDate]: {
                selected: true,
                selectedColor: '#FDF05D',
              }
            }}
            markingType={'simple'}
            theme={{
              todayTextColor: '#FDF05D',
              selectedDayBackgroundColor: '#FDF05D',
              selectedDayTextColor: 'black',
            }}
          />
        </View>

        {renderBookedSlots()}

        <View style={styles.timePickerContainer}>
          <Text style={styles.timePickerLabel}>Select Time:</Text>
          <ScrollView style={styles.timeScrollView}>
            {Array.from({ length: 24 * 2 }, (_, i) => {
              const hour = Math.floor(i / 2) + 1;
              const minute = (i % 2) === 0 ? '00' : '30';
              const ampm = hour < 12 ? 'AM' : 'PM';
              const timeLabel = `${hour > 12 ? hour - 12 : hour}:${minute} ${ampm}`;

              return (
                <TouchableOpacity
                  key={i}
                  style={[
                    styles.timeSlot,
                    selectedTime === timeLabel && styles.selectedTimeSlot
                  ]}
                  onPress={() => setSelectedTime(timeLabel)}
                >
                  <Text style={styles.timeSlotText}>{timeLabel}</Text>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>

        <View style={styles.messageInputContainer}>
          <Text style={styles.sectionTitle}>Add a message (Optional):</Text>
          <TextInput
            style={styles.messageInput}
            placeholder="Write your message here..."
            value={message}
            onChangeText={setMessage}
          />
        </View>

        <TouchableOpacity style={styles.button} onPress={handleCollabPress}>
          <Text style={styles.buttonText}>Submit Collab Proposal</Text>
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingHorizontal: 16,
  },
  backContainer: {
    marginBottom: 16,
    marginTop: 20,
  },
  backButton: {
    alignSelf: 'flex-start',
  },
  logoContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  logo: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  section: {
    marginBottom: 16,
  },
  headerSectionTitle: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  descriptionBox: {
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    padding: 10,
    marginTop: 8,
  },
  description: {
    fontSize: 16,
    color: '#666',
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  sectionTitleAddress: {
    fontSize: 16,
    marginLeft: 8,
  },
  creditsCard: {
    backgroundColor: '#FDF05D',
    padding: 16,
    borderRadius: 10,
    marginBottom: 16,
    alignItems: 'center',
  },
  creditsCardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 8,
  },
  creditsCardSubTitle: {
    fontSize: 16,
  },
  calendarContainer: {
    marginBottom: 16,
  },
  bookedSlotsContainer: {
    marginBottom: 16,
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    padding: 10,
  },
  bookedSlotsTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  bookedSlotsBody: {
    fontSize: 10,
    marginBottom: 8,
  },
  bookedSlot: {
    marginBottom: 4,
  },
  bookedSlotText: {
    fontSize: 16,
  },
  expandButton: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandButtonText: {
    color: '#007BFF',
    fontSize: 16,
    textDecorationLine: 'underline',
    marginLeft: 4,
  },
  timePickerContainer: {
    marginBottom: 16,
  },
  timePickerLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  timeScrollView: {
    height: 120,
  },
  timeSlot: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: '#eee',
  },
  timeSlotText: {
    fontSize: 16,
  },
  selectedTimeSlot: {
    backgroundColor: '#FDF05D',
  },
  messageInputContainer: {
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  messageInput: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 10,
    fontSize: 16,
    backgroundColor: '#f0f0f0',
  },
  button: {
    backgroundColor: '#FDF05D',
    paddingVertical: 14,
    borderRadius: 8,
    alignItems: 'center',
    marginBottom: 30,
  },
  buttonText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
});

export default CreateSubmissionPage;
