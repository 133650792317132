import React from 'react';
import { SafeAreaView, StyleSheet, View, Text, ScrollView, Image, TextInput, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const App = ({ navigation }) => {

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.searchBar}>
        <TextInput
          style={styles.searchInput}
          placeholder="Search..."
          placeholderTextColor="#666"
        />
      </View>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Dashboard Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>My Codes</Text>
          {/* First Row */}
          <View style={styles.cardRow}>
            <TouchableOpacity style={styles.card} onPress={() => navigation.navigate('CollabCampaignDetailsPage')}>
              <View style={styles.cornerTopLeft} />
              <View style={styles.cornerTopRight} />
              <View style={styles.cornerBottomLeft} />
              <View style={styles.cornerBottomRight} />
              <Ionicons name="qr-code" size={24} color="black" />
              <Image style={styles.logo}
                source={{ uri: 'https://images.pexels.com/photos/2253643/pexels-photo-2253643.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' }}

              />
              <Text style={styles.cardTitle}>Pret</Text>
              <Text style={styles.cardSubText}>10% Off</Text>
            </TouchableOpacity>


            <TouchableOpacity style={styles.card} onPress={() => navigation.navigate('CollabCampaignDetailsPage')}>
              <View style={styles.cornerTopLeft} />
              <View style={styles.cornerTopRight} />
              <View style={styles.cornerBottomLeft} />
              <View style={styles.cornerBottomRight} />
              <Ionicons name="qr-code" size={24} color="black" />
              <Image style={styles.logo}
                source={{ uri: 'https://images.pexels.com/photos/2253643/pexels-photo-2253643.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' }}

              />
              <Text style={styles.cardTitle}>HollyWood Bowl</Text>
              <Text style={styles.cardSubText}>Free Drink</Text>
            </TouchableOpacity>
          </View>
          {/* Second Row */}
          <View style={styles.cardRow}>
            <TouchableOpacity style={styles.card}>
              <View style={styles.cornerTopLeft} />
              <View style={styles.cornerTopRight} />
              <View style={styles.cornerBottomLeft} />
              <View style={styles.cornerBottomRight} />
              <Ionicons name="qr-code" size={24} color="black" />
              <Image style={styles.logo}
                source={{ uri: 'https://images.pexels.com/photos/2253643/pexels-photo-2253643.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' }}
              />
              <Text style={styles.cardTitle}>GymBo™</Text>
              <Text style={styles.cardSubText}>10 Points</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.card}>
              <View style={styles.cornerTopLeft} />
              <View style={styles.cornerTopRight} />
              <View style={styles.cornerBottomLeft} />
              <View style={styles.cornerBottomRight} />
              <Ionicons name="qr-code" size={24} color="black" />
              <Image style={styles.logo}
                source={{ uri: 'https://images.pexels.com/photos/2253643/pexels-photo-2253643.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' }}
              />
              <Text style={styles.cardTitle}>SmallCoffeeCo.</Text>
              <Text style={styles.cardSubText}>Free Latte</Text>
            </TouchableOpacity>
          </View>
          {/* Add more rows as needed */}
        </View>
      </ScrollView>


      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="qr-code" size={24} color="black" onPress={() => navigation.navigate('UserQRDashboard')} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserRecapPage')}>
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="cash" size={24} color="black" />
        </TouchableOpacity>
      </View>


    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  searchBar: {
    backgroundColor: '#fff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  searchInput: {
    backgroundColor: 'black',
    color: 'white',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 20,
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center',
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 10,
  padding: 5,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  card: {
    width: '48%',
    height: 150,
    backgroundColor: '#f0f0f0',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative', // Add this to position corners relative to the card
  },
  logo: {
    width: 40,
    height: 40,
    marginBottom: 5,
    borderRadius: 150,
    padding: 10,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  cardSubText: {
    fontSize: 12,
    color: '#666',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,

  },
  footerIcon: {
    alignItems: 'center',
  },

});

export default App;
