import React, { useState, useEffect } from 'react';
import { 
  SafeAreaView, 
  StyleSheet, 
  View, 
  Text, 
  ScrollView, 
  Image, 
  TouchableOpacity 
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const UserRecapPage = ({ navigation }) => {
  const [listingData, setListingData] = useState(null);

  useEffect(() => {
    // Fetch data from PHP file when the component mounts
    const fetchListingData = async () => {
      try {
        const response = await fetch('http://192.168.0.7/User_App_v3.0/APIControllers/RetrieveAssignableQR.php?CollabID=09765');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setListingData(data);
      } catch (error) {
        console.error('There was a problem fetching the listing data:', error);
      }
    };

    fetchListingData();
  }, []);

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleGetQR = async () => {
    try {
      const response = await fetch('http://192.168.0.7/User_App_v3.0/APIControllers/InsertAssignedQR.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CollabID: '09765', // Assuming this is a constant value
          Promoter: listingData?.Promoter, // Assuming Promoter is a key in listingData
          Redeemer: '', // You might need to define Redeemer
          Expiry_Date: '', // You might need to define Expiry_Date
          QR_Image: '', // You might need to define QR_Image
          Redeem_Message: '', // You might need to define Redeem_Message
          CTA_Type: '' // You might need to define CTA_Type
        }),
      });
      const data = await response.json();
      console.log(data); // handle success message or error
    } catch (error) {
      console.error('There was a problem inserting data:', error);
    }
  };
  
  
  
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {/* Trendy Slide Section */}
        <View style={styles.trendyBanner}>
          <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
            <Ionicons name="arrow-back" size={28} color="white" />
          </TouchableOpacity>
          <Image
            style={styles.trendyImage}
            source={{ uri: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Pret_A_Manger_logo_new.jpg/800px-Pret_A_Manger_logo_new.jpg' }}
            resizeMode="cover"
          />
        </View>

        {/* Profile Section */}
        <View style={styles.profileSection}>
          <Image
            style={styles.profilePicture}
            source={{ uri: 'https://images.pexels.com/photos/3030332/pexels-photo-3030332.jpeg?auto=compress&cs=tinysrgb&w=300' }}
            resizeMode="cover"
          />
          <View style={styles.profileDetails}>
            {/* Username */}
            <View style={styles.usernameContainer}>
              <Text style={styles.usernameText}>@{listingData ? listingData.Promoter : ''}</Text>
            </View>
            {/* Name */}
            <Text style={styles.name}>{listingData ? listingData.Promoter : ''} is offering you a QR code for {listingData ? listingData.BrandName : ''}</Text>
          </View>
        </View>

        {/* Text Section */}
        <View style={styles.textSection}>
          <View style={styles.textBackground}>
            <Text style={styles.offerText}>
              {listingData ? listingData.Offer : ''}
            </Text>
          </View>
        </View>

        {/* Interest Section */}
        <View style={styles.section}>
          {/* Dynamic Interests */}
        </View>
        <TouchableOpacity style={styles.button} onPress={handleGetQR}>
          <Text style={styles.buttonText}>Get QR</Text>
        </TouchableOpacity>
        {/* Social Media Section */}
      </ScrollView>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="qr-code" size={24} color="black" onPress={() => navigation.navigate('UserQRDashboard')}/>
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('UserRecapPage')}>
          <Ionicons name="person" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('Homepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon}>
          <Ionicons name="cash" size={24} color="black" />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};


// Define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  contentContainer: {
    paddingBottom: 60, // Adjusted for the footer height
    paddingHorizontal: 10,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  section: {
    marginBottom: 20,
  },
  card: {
    width: 80,
    height: 80,
    backgroundColor: 'black',
    marginRight: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  interestContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  interest: {
    backgroundColor: '#f0f0f0',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    marginBottom: 10,
    marginHorizontal: 5,
    alignItems: 'center',
  },
  interestText: {
    fontSize: 16,
  },
  profileSection: {
    position: 'relative',
  },
  profilePicture: {
    width: 150,
    height: 150,
    borderRadius: 75,
    position: 'absolute',
    top: -75, // Adjust as needed
    left: '50%',
    marginLeft: -75, // Center horizontally
    borderWidth: 5, // Just for visibility
    borderColor: '#fff', // Just for visibility
  },
  profileDetails: {
    marginTop: 100, // Should match the negative of top in profilePicture
    alignItems: 'center',
  },
  usernameContainer: {
    backgroundColor: 'black',
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  usernameText: {
    fontSize: 20,
    fontWeight: 'bold',
    width: '50%',
    color: 'white',
  },
  name: {
    fontSize: 18,
    paddingTop: 10,
    color: '#666',
    textAlign: 'center',
    marginBottom: '30%',
  },
  bio: {
paddingTop: 2,
    marginBottom: 5,
  },
  age: {
    fontSize: 18,
    color: '#666',
    fontWeight: 'bold',
    paddingBottom: 10,
  },
  trendyBanner: {
    marginBottom: 20,
    borderRadius: 10,
    overflow: 'hidden',
    borderColor: '#f3f3f3', // Set border color to black
    borderWidth: 2,       // Set border width
  },
  trendyImage: {
    width: '100%',
    height: 150,
    borderRadius: 5, 
},
  trendyText: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold',
  },
  textSection: {
    marginBottom: 20,
    paddingHorizontal: 10,

  },
  textBackground: {
    backgroundColor: 'black',
    padding: 20,
    borderRadius: 10,
  },
  text: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    lineHeight: 22,
  },
  offerText: {
    fontSize: 30,
    color: 'white',
    textAlign: 'center',
    padding: 5,
  },
  backButton: {
    position: 'absolute',
    top: 9, // adjust as needed
    left: 9, // adjust as needed
    zIndex: 1, // ensure the button is above the image
    padding: 10,
    backgroundColor: 'transparent', // no background color
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,

  },
  footerIcon: {
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'green',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    borderRadius: 10,
    position: 'absolute',
    bottom: 20,
    left: 20,
    right: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',

  },
  
});


export default UserRecapPage;
